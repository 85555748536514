<script>
import IntersectionObserver from './IntersectionObserver.svelte'

export let moduleType
export let module
</script>

<IntersectionObserver let:intersecting={isVisible} threshold={0.2}>
  <svelte:component this={moduleType} {...module} {isVisible} />
</IntersectionObserver>
