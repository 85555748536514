<script>
export let text
export let highlight = null
export let map = false
</script>

<h2 class:map>
  {text}
  {#if highlight}
    <span class="highlight">{highlight}</span>
  {/if}
</h2>

<style>
h2 {
  margin: var(--margin-xs) auto;
  font-size: var(--font-title);    
  text-transform: uppercase;
  font-family: 'Saira Semi Condensed', sans-serif;
  font-style: normal;
  line-height: 1.25em;
  color: var(--blue-primary-700);
}
h2 .highlight {
  background-color: var(--blue-primary-700);
  color: var(--yellow-secondary-400);
  padding: 0 var(--margin-xs);
}
</style>
