<script>
    import { onMount } from 'svelte'
    import { fade } from 'svelte/transition';
    import IntersectionObserver from "./IntersectionObserver.svelte";
    
    export let number
    export let duration
    export let label

    let treemapVisible = false
    let numberVisible = true
    
    let animatedValue = 0
    let stringNumber = '0'
    let isAnimating = false

    let viewport = 'mobile'
    function setViewportMq(_) {
      viewport = window.innerWidth >= 667 ? 'desktop' : window.innerWidth >= 480 ? 'tablet' : 'mobile'
    }
    onMount(() => {
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', setViewportMq)
        setViewportMq()
      }
    })
    
    function switchFigure(){
      numberVisible = false;
      treemapVisible = true;
    }

    const animateValue = (start, end, duration) => {
      let rAF
      let startTimestamp = null
    
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        animatedValue = Math.floor(progress * (end - start) + start)

        stringNumber = animatedValue.toString()
    
        if (progress < 1) {
          rAF = window.requestAnimationFrame(step)
        } else {
          window.cancelAnimationFrame(rAF)
          stringNumber = "248,1"
          setTimeout(function(){ switchFigure(); }, 1000);
          
        }
      }
    
      rAF = window.requestAnimationFrame(step)
    }
    
    const runAnimation = (_) => {
      if (!isAnimating) {
        isAnimating = true
        animateValue(0, number, duration)
      }
    }
    </script>
    
    <div class="outer">
        <IntersectionObserver on:intersecting={runAnimation} threshold={0.7}>
          {#if numberVisible}
            <div class="wrapper" out:fade>
                <div class="inner">
                    <div class="animated">{stringNumber}</div>
                    <div class="label">{label}</div>
                </div>
            </div>
          {/if}

          {#if treemapVisible}
            <div class="img-container" in:fade>
              {#if viewport === 'mobile'}
                <svg width="288" height="600" viewBox="0 0 288 600" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M167 0H0V284H167V0Z" fill="#03387B"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="3" y="17.0092">Rivoluzione verde&#10;</tspan><tspan x="3" y="35.0092">e transizione&#10;</tspan><tspan x="3" y="53.0092">ecologica</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="3" y="71.0092">68,6 mld</tspan></text>
                  <path d="M288 0H169V284H288V0Z" fill="#3073A7"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="172" y="17.0092">Digitalizzazione,&#10;</tspan><tspan x="172" y="35.0092">innovazione,&#10;</tspan><tspan x="172" y="53.0092">competitivit&#xe0;&#10;</tspan><tspan x="172" y="71.0092">e cultura</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="172" y="89.0092">49,2 mld</tspan></text>
                  <path d="M144 286H0V438H144V286Z" fill="#57A7CE"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="3" y="303.009">Istruzione e ricerca</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="3" y="321.009">31,9 mld</tspan></text>
                  <path d="M288 286H146V438H288V286Z" fill="#58A9D0"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="149" y="303.009">Infrastrutture&#10;</tspan><tspan x="149" y="321.009">per mobilit&#xe0;&#10;</tspan><tspan x="149" y="339.009">sostenibile</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="149" y="357.009">31,4 mld</tspan></text>
                  <path d="M208 440H0V525H208V440Z" fill="#65B9DC"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="3" y="457.009">Altre opere specifiche</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="3" y="475.009">26,0 mld</tspan></text>
                  <path d="M208 527H0V600H208V527Z" fill="#6DC4E4"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="3" y="544.009">Inclusione e coesione</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="3" y="562.009">22,4 mld</tspan></text>
                  <path d="M288 440H210V600H288V440Z" fill="#76CFED"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="213" y="457.009">Salute</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="213" y="475.009">18.5 mld</tspan></text>
                </svg>                
              {:else if viewport === 'tablet'}
                <svg width="448" height="480" viewBox="0 0 480 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M289 0H0V219H289V0Z" fill="#03387B"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="3" y="20.0092">Rivoluzione verde e&#10;</tspan><tspan x="3" y="38.0092">transizione ecologica</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="3" y="56.0092">68,6 mld</tspan></text>
                  <path d="M289 221H0V377H289V221Z" fill="#3073A7"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="3" y="241.009">Digitalizzazione, innovazione,&#10;</tspan><tspan x="3" y="259.009">competitivit&#xe0; e cultura</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="3" y="277.009">49,2 mld</tspan></text>
                  <path d="M289 379H0V480H289V379Z" fill="#57A7CE"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="3" y="399.009">Istruzione e ricerca</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="3" y="417.009">31,9 mld</tspan></text>
                  <path d="M480 0H291V152H480V0Z" fill="#58A9D0"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="294" y="20.0092">Infrastrutture per&#10;</tspan><tspan x="294" y="38.0092">mobilit&#xe0; sostenibile</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="294" y="56.0092">31,4 mld</tspan></text>
                  <path d="M392 154H291V389H392V154Z" fill="#65B9DC"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="294" y="174.009">Altre opere&#10;</tspan><tspan x="294" y="192.009">specifiche</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="294" y="210.009">26,0 mld</tspan></text>
                  <path d="M480 154H394V389H480V154Z" fill="#6DC4E4"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="397" y="174.009">Inclusione&#10;</tspan><tspan x="397" y="192.009">e coesione</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="397" y="210.009">22,4 mld</tspan></text>
                  <path d="M480 391H291V480H480V391Z" fill="#76CFED"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" letter-spacing="0em"><tspan x="294" y="411.009">Salute</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="14" font-weight="600" letter-spacing="0em"><tspan x="294" y="429.009">18,5 mld</tspan></text>
                </svg>                
              {:else}
                <svg width="608" height="480" viewBox="0 0 640 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M303 0H0V279H303V0Z" fill="#03387B"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" letter-spacing="0em"><tspan x="3" y="19.1657">Rivoluzione verde e&#10;</tspan><tspan x="3" y="36.1657">transizione ecologica</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" font-weight="600" letter-spacing="0em"><tspan x="3" y="52.1657">68,6 mld</tspan></text>
                  <path d="M303 281H0V480H303V281Z" fill="#3073A7"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" letter-spacing="0em"><tspan x="3" y="300.166">Digitalizzazione, innovazione,&#10;</tspan><tspan x="3" y="317.166">competitivit&#xe0; e cultura</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" font-weight="600" letter-spacing="0em"><tspan x="3" y="333.166">49,2 mld</tspan></text>
                  <path d="M473 0H305V232H473V0Z" fill="#57A7CE"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" letter-spacing="0em"><tspan x="308" y="19.1657">Istruzione e ricerca</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" font-weight="600" letter-spacing="0em"><tspan x="308" y="34.1657">31,9 mld</tspan></text>
                  <path d="M640 0H475V232H640V0Z" fill="#58A9D0"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" letter-spacing="0em"><tspan x="478" y="19.1657">Infrastrutture per&#10;</tspan><tspan x="478" y="36.1657">mobilit&#xe0; sostenibile</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" font-weight="600" letter-spacing="0em"><tspan x="478" y="52.1657">31,4 mld</tspan></text>
                  <path d="M547 234H305V365H547V234Z" fill="#65B9DC"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" letter-spacing="0em"><tspan x="308" y="253.166">Altre opere specifiche</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" font-weight="600" letter-spacing="0em"><tspan x="308" y="268.166">26,0 mld</tspan></text>
                  <path d="M547 367H305V480H547V367Z" fill="#6DC4E4"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" letter-spacing="0em"><tspan x="308" y="386.166">Inclusione e coesione</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" font-weight="600" letter-spacing="0em"><tspan x="308" y="401.166">22,4 mld</tspan></text>
                  <path d="M640 234H549V480H640V234Z" fill="#76CFED"/>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" letter-spacing="0em"><tspan x="552" y="253.166">Salute</tspan></text>
                  <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Work Sans" font-size="13" font-weight="600" letter-spacing="0em"><tspan x="552" y="268.166">18,5 mld</tspan></text>
                </svg>
              {/if}
            </div>
          {/if}

        </IntersectionObserver>
    </div>

    <p class="caption">Fonte: Ministero dello sviluppo economico</p>
    
    <style>

    .outer{
        position: relative;
        width: 100%;
        max-width: 288px;
        height: 600px;
        margin: auto;
        margin-bottom: 1em;
        margin-top: 1em;
        overflow: hidden;
    }

    @media (min-width: 480px){
        .outer{
            height: 480px;
            max-width: 448px;
        }
    }

    @media (min-width: 667px){
        .outer{
            height: 480px;
            max-width: 608px;
        }
    }

    .wrapper {
        width: 100%;
        height: 100%;
        text-align: center;
        color: white;
        background: linear-gradient(232.27deg, #77D1EE 0%, #003478 100%);
        padding: 3rem 1.5rem 3rem;
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 1;
    }

    .inner{
        margin: auto;
    }
    
    .animated {
      font-size: 6rem;
      font-weight: 400;
    }
    
    .label {
      font-size: 2.125rem;
      font-weight: 400;
    }
    
    .img-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
    }

    .caption{
        font-size: 0.875em;
        font-weight: 400;
        color: #8C8C8C;
        text-align: center;
        margin-bottom: 2em;
    }

    </style>
    