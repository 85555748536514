const { cubicInOut, linear } = require('svelte/easing')

const map = (v, in_min, in_max, out_min, out_max) =>
  ((v - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min

module.exports.animateHtmlHeight = function (node, params) {
  const targetY = +node.style.top.replace('px', '')
  const targetHeight = +node.style.height.replace('px', '')
  node.style.height = 0
  node.style.top = 0

  return {
    delay: params.delay || 400,
    duration: params.duration || 700,
    easing: params.easing || cubicInOut,
    tick: (t) => {
      const height = t * targetHeight
      node.style.height = `${height}px`
      node.style.top = `${targetY - (-targetHeight + height)}px`
    },
  }
}

module.exports.animateHeight = function (node, params) {
  const targetY = +node.getAttribute('y')
  const targetHeight = +node.getAttribute('height')
  node.setAttribute('height', 0)
  node.setAttribute('y', 0)

  return {
    delay: params.delay || 400,
    duration: params.duration || 700,
    easing: params.easing || cubicInOut,
    tick: (t) => {
      const height = t * targetHeight
      node.setAttribute('height', height)
      node.setAttribute('y', targetY - (-targetHeight + height))
    },
  }
}

module.exports.animateAngle = function (node, params) {
  return {
    delay: params.delay || 200,
    duration: params.duration || 700,
    easing: params.easing || cubicInOut,
    tick: (t) => {
      const endAngle = map(t, 0, 1, params.datum.startAngle, params.datum.endAngle)
      node.setAttribute('d', params.arcFn({ ...params.datum, endAngle }))
    },
  }
}

module.exports.animateWidth = function (node, params) {
  const targetWidthPerc = +node.dataset.widthPerc
  return {
    delay: params.delay || 400,
    duration: params.duration || 300,
    easing: params.easing || cubicInOut,
    tick: (t) => {
      const width = map(t, 0, 1, 0, targetWidthPerc)
      node.style.width = `${width}%`
    },
  }
}

module.exports.animateOpacityFromTop = function (node, { delay = 0, duration = 300 }) {
  node.setAttribute('opacity', 0)
  node.setAttribute('transform', 'translate(-10 0)')

  return {
    delay,
    duration,
    tick: (t) => {
      node.setAttribute('opacity', t)
      node.setAttribute('transform', `translate(-${(1 - t) * 10} 0)`)
    },
  }
}

module.exports.animateOpacityHtml = function (node, { delay = 0, duration = 300 }) {
  const originalOpacity = +node.style.opacity
  node.style.opacity = 0

  return {
    delay,
    duration,
    tick: (t) => {
      node.style.opacity = t
    },
  }
}

module.exports.animateOpacity = function (node, { delay = 0, duration = 300 }) {
  const originalOpacity = +node.getAttribute('opacity')
  node.setAttribute('opacity', 0)

  return {
    delay,
    duration,
    tick: (t) => {
      const mappedOpacity = map(t, 0, 1, 0, originalOpacity)
      node.setAttribute('opacity', mappedOpacity)
    },
  }
}

module.exports.animateX2 = function (node, params) {
  const startX = +node.getAttribute('x1')
  const targetX = +node.getAttribute('x2')

  return {
    delay: params.delay || 400,
    duration: params.duration || 300,
    easing: params.easing || cubicInOut,
    tick: (t) => {
      const x = map(t, 0, 1, startX, targetX)
      node.setAttribute('x2', x)
    },
  }
}

module.exports.fadeScale = function (node, params) {
  const scaleFactor = 1.2

  return {
    delay: params.delay || 400,
    duration: params.duration || 300,
    easing: params.easing || linear,
    tick: (t) => {
      let opacity, scale
      if (t <= 0.5) {
        opacity = t * 2
        scale = t * scaleFactor * 2
      } else {
        opacity = 1
        scale = map(t, 0.5, 1, scaleFactor, 1)
      }

      node.style.opacity = opacity
      node.style.transform = 'scale(' + scale + ')'
    },
  }
}

module.exports.stay = function (node, params) {
  return {
    delay: params.delay || 400,
    duration: params.duration || 300,
    easing: params.easing || linear,
    tick: (t) => {
      node.style.opacity = 1
      node.style.transform = 'scale(1)'
    },
  }
}

module.exports.appear = function (node, { delay = 0, duration = 300 }) {
  return {
    delay,
    duration,
    css: (t) => `opacity: ${t}; transform: rotate(90deg) translate(0, ${1 - t}rem);`,
  }
}
