<script>
  import { onMount, onDestroy } from 'svelte'
  import Caption from './Caption.svelte'
  export let src
  export let mobileSrc
  export let absolute = false 
  export let cover = false
  export let caption
  export let captionColor
  export let poster
  let videoEl
  let isVisible = false
  // Inline media queries for HTML5 videos don't work in Chrome :(
  // See: https://stackoverflow.com/questions/25907930/chrome-not-respecting-video-source-inline-media-queries
  let viewport = 'mobile'
  function setViewportMq(_) {
    viewport = window.innerWidth <= 768 ? 'mobile' : 'desktop'
  }
  function fadeVideo(_) {
    isVisible = true
  }
  onMount(() => {
    if (typeof window !== 'undefined' && mobileSrc) {
      window.addEventListener('resize', setViewportMq)
      setViewportMq()
      if (videoEl) {
        videoEl.readyState >= 3 ? fadeVideo() : videoEl.addEventListener('canplaythrough', fadeVideo)
      }
    }
  })
  onDestroy(() => {
    if (typeof window !== 'undefined' && mobileSrc) {
      window.removeEventListener('resize', setViewportMq)
      videoEl && videoEl.removeEventListener('canplaythrough', fadeVideo)
    }
  })
  </script>
  
  <div class="wrapper" class:cover class:absolute>
    {#if mobileSrc && viewport === 'mobile'}
      <video class:visible={isVisible} autoplay muted loop controls={false} bind:this={videoEl} {poster}>
        <source src={mobileSrc} type="video/mp4" />
      </video>
    {:else}
      <video class:visible={isVisible} autoplay muted loop controls={false} bind:this={videoEl} {poster}>
        <source {src} type="video/mp4" />
      </video>
    {/if}
    {#if caption}
      <Caption {caption} color={captionColor} />
    {/if}
  </div>
  
  <style>
.wrapper {
  width: 100%;
  height: 100%;      
}

video {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s opacity ease-in-out;
}
.visible {
  opacity: 1;
}

.cover video {
  object-fit: cover;
}

.wrapper:not(.cover) {
  /* max-width: 42rem; */
  padding: 0 1rem;
  margin: 0 auto 4rem;
}

.wrapper.absolute video {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;      
  z-index: -1;   
}

</style>