<script>
export let current
export let clicked
export let areas
</script>

<div class="wrapper">
  {#if areas}
  {#each areas as area}
    <div
      class="map-btn"
      on:click={() => {
        current = area
        clicked = true
        setTimeout(() => {
          clicked = false
        }, 1000)
      }}
      class:selected-map-btn={current === area}>
      {#if area['regione'] === 'Italia'}
        <svg
          width="20"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          style="margin-right: 4px;"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M9.23565 2.28198C9.79103 0.572674 12.2092 0.572675 12.7646 2.28198L13.2543 3.78918C13.7705 5.37765 15.2507 6.45313 16.921 6.45313H18.5057C20.303 6.45313 21.0503 8.75298 19.5962 9.80938L18.3141 10.7409C16.9629 11.7226 16.3975 13.4628 16.9136 15.0512L17.4033 16.5584C17.9587 18.2677 16.0023 19.6891 14.5483 18.6327L13.2662 17.7012C11.915 16.7195 10.0853 16.7195 8.73405 17.7012L7.45195 18.6327C5.99793 19.6891 4.04156 18.2677 4.59694 16.5584L5.08666 15.0512C5.60279 13.4628 5.03738 11.7226 3.68615 10.7409L2.40404 9.80938C0.950023 8.75298 1.69729 6.45313 3.49456 6.45313H5.07932C6.74954 6.45313 8.2298 5.37765 8.74593 3.78918L9.23565 2.28198Z"
            stroke="#00007C"
            stroke-width="2" /></svg>
      {/if}
      {area['regione']}
    </div>
  {/each}
  {/if}
</div>

<style>
.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--margin);
  justify-content: start;
}
.map-btn {
  cursor: pointer;
  margin: 0.1875rem;
  padding: 0 0.75rem;
  border: 2px solid #00007c;
  color: #00007c;
  display: flex;
  height: 2.25rem;
  align-items: center;
}
.selected-map-btn {
  color: white;
  background-color: #00007c;
  stroke: white;
}
:global(.selected-map-btn path) {
  stroke: white;
}
@media (min-width: 768px) {
  .wrapper {
    justify-content: center;
  }
}
</style>
