<script>
export let caption
export let align = 'center'
</script>

{#if caption}
  <p class="caption" style="text-align: {align};">{caption}</p>
{/if}

<style>
.caption {
  margin: var(--margin-s) 0;
  font-size: 0.875em;
  line-height: 1.375;
  color: #8C8C8C;
  font-weight: 400;
}
</style>
