<svg width="29" height="30" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect
    x="1"
    y="1.98535"
    width="26.5833"
    height="26.5833"
    rx="3"
    fill="#00B7FF"
    stroke="#007AFF"
    stroke-width="2" />
</svg>
