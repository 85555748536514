<script>
import { onMount } from 'svelte'
import projectsINT from '../data/projects_INT.json'
import projectsITA from '../data/projects_IT.json'
import Dropdown from './Dropdown.svelte';
import { conf } from '../lib/const'
import P from './P.svelte';
import ProjectsBox from './ProjectsBox.svelte'
import ProjectsLegend from './ProjectsLegend.svelte'

let selected = ""
let diffEmptyGrid = 0
let activeP = []

const GRID = 5

const orderByNumActiveP = projects => projects ? projects.map(p => ({ 
  ...p,
  sum: p.progetti.reduce((a, x) => a +x )  
})).sort((a, b) => b.sum - a.sum) : []

const getPByRegion = (r) => r ? projectsINT.filter((p) => p['region'] === r) : projectsINT   

const getEmptyPositionalElements = length => {
  if (length % GRID !== 0) {    
    const elementsLength = GRID - (length % GRID)
    return Array.from(Array(elementsLength).fill(0))
  }
  return []
}

onMount(() => {
  activeP = orderByNumActiveP(getPByRegion(selected))
  diffEmptyGrid = getEmptyPositionalElements(activeP.length)
})
// onMount(() => {
//   let viewport = window.innerWidth <= 768 ? 'mobile' : 'desktop'
//   boxesToDisplay = viewport == 'mobile' ? 10 : projects.length
// })
// $: boxesToDisplay = 0
// $: hiddenBoxes = projects.length - boxesToDisplay
$: {  
  activeP = orderByNumActiveP(getPByRegion(selected))
  diffEmptyGrid = getEmptyPositionalElements(activeP.length)  
}
</script>

<div class="wrapper">  
  <!-- ITALY -->
  <div class="italy">
    <Dropdown      
      bind:selected={selected} 
      values={conf.selectValuesIT}
      disabled
    />
    <section>
    <div class="sidebar">
      <ProjectsLegend type='IT'/>
    </div>
    <div class="grid">
      {#each orderByNumActiveP(projectsITA) as p, idx}
      <!-- <div class:hidden={idx >= boxesToDisplay}> -->
      <ProjectsBox countryName={p['country']} activeProjects={p['progetti']} {idx} isRound />
      {/each}
    </div>
    </section>
  </div>
  <!-- ESTERO  -->
  <div class="ww">
    <Dropdown      
      bind:selected={selected} 
      values={conf.selectValues}
    />
    <section>
    <div class="sidebar">
      <ProjectsLegend />
    </div>    
    <div class="grid">
    {#each activeP as p, idx}
      <!-- <div class:hidden={idx >= boxesToDisplay}> -->
      <ProjectsBox countryName={p['country']} activeProjects={p['progetti']} {idx} />
    {/each}
    {#each diffEmptyGrid as d}
      <ProjectsBox isEmpty /> 
    {/each}
    <!-- {#if hiddenBoxes > 0}
      <div
        on:click={() => {
          boxesToDisplay = filteredProjects.length
        }}
        class="load">
        Carica gli altri 15 paesi
      </div>
    {/if} -->
    </div>
    </section>
  </div>
</div>

<style>
.wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;  
}

section .sidebar {
  width: 100%;
}
/* .load {
  padding-top: 0.625rem;
  width: 100%;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
} */
section .grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;  
}

@media (min-width: 992px) {
  section {  
    display: flex;    
  }
  section .sidebar {
    width: 25%;
    padding: 0 var(--margin-xs) 0 0;
  }
  section .grid {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
/* .hidden {
  width: 0;
  height: 0;
  opacity: 0;
} */
</style>
