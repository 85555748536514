<script>
export let svg_code
export let caption
export let space = false
</script>

<div class="wrapper">
  {@html svg_code}
  {#if caption}
    <p class="caption" class:space>{@html caption}</p>
  {/if}
</div>

<style>
.caption {
  font-size: 0.875em;
  font-weight: 400;
  text-align: center;
}

.space {
  margin-bottom: 2em;
}

.caption :global(.small) {
  color: #8c8c8c;
}

.wrapper {
  margin: auto;
  width: fit-content;
  text-align: center;
}

p :global(.caption-bold) {
  font-weight: 600;
}
</style>
