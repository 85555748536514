<script>
import { data } from './data/contents.js'
import Hero from './components/Hero.svelte'
import Section from './components/Section.svelte'
import PreFooter from './components/PreFooter.svelte'
import Video from './components/Video.svelte'
import FixedHeader from './components/FixedHeader.svelte'
</script>

<main>
  <FixedHeader />
  <div class="inner">
    <!-- <div
      class="placeholder"
      style="position: absolute; width: 100%; height: 100%; left: 0; top: 0; z-index: -10;">
      <img
        src={'./assets/blurred.jpg'}
        alt="immagine di placeholder in attesa del video"
        style="width: 100%; height: 100%; object-fit: cover;" />
    </div> -->
    <!-- !!! Removed temporary -->
    <!-- <Video {...data['sectionsAttachedVideo']} /> -->
  </div>
  <Hero {...data['hero']} />
  {#each data['sections'] as section}
    <Section {...section} />
  {/each}
  <PreFooter {...data['preFooter']} />
</main>

<style>
main {
  height: 100%;
}
.inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
:global(:root){  
  /* 10px */
  --margin-xs: 0.556rem;
  /* 14px */
  --margin-s: 0.778rem;
  --margin: 1em;
  --margin-m: 1.333em;
  --margin-l: 1.555rem;
  --margin-xl: 4.444rem;
  --margin-xxl: 5rem;
  --blue-primary-700: #00007C;
  --blue-primary-600: #0000BA;
  --blue-primary-500: #0000FF;
  --blue-primary-400: #007AFF;
  --blue-primary-300: #00B7FF;
  --yellow-secondary-400: #FFDB48; 
  /* 18px */
  --font-body: 1rem; 
  /* 16px */
  --font-body-sm: 0.888rem;
  /* 24px */
  --font-title-xs: 1.333rem;
  /* 48px */
  --font-title: 2.666rem;  
  /* 28px */
  --font-title-sm: 1.555rem;
  /* 80px */
  --font-super: 4.444rem;
}
</style>
