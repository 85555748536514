<script>
import { conf } from '../lib/const'
</script>

<div class="wrapper">
  <strong>Progetti attivi</strong>
  <div class="legend-wrapper">
    {#if conf.colors}
      {#each conf.colors as color, i}
      <div class="rect-wrapper">
        <div class="rectangle" style="background: {color};" />
        <p>{i}{i === 5 ? '+' : ''}</p>
      </div>
      {/each}
    {/if}
  </div>
</div>

<style>
.wrapper {
  margin: 0 auto;
  max-width: 30rem;
  font-size: 14px;
}
.legend-wrapper {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 0.5rem;
}
.rectangle {
  height: 0.5rem;
  width: 2rem;
}
@media (min-width: 1200px) {
  .wrapper {
    margin: 0;
    padding: 0;
  }
}
</style>
