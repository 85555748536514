<script>
import { onMount } from 'svelte'
import { absoluteUrl } from '../lib/with-url'
import Caption from './Caption.svelte'

export let src
export let alt
export let sources = []
export let caption
export let style = 'block'
export let width = undefined
let isObjectFit = true
let fallbackCss = ''

onMount(() => {
  if (typeof window !== 'undefined') {
    isObjectFit = window.Modernizr.objectfit && window.Modernizr['object-fit']
    fallbackCss = "background-image: url('" + absoluteUrl(src) + "');"
  }
})
// Img width and height is hard to fix with picture,
// but check ref here: https://www.smashingmagazine.com/2020/03/setting-height-width-images-important-again/
</script>

{#if !isObjectFit && style === 'cover'}
  <!-- very old browser -->
  <div class={style} style="{fallbackCss} background-size: cover; background-position: center;" />
{:else if sources}
  <picture>
    {#each sources as source}
      <source srcset="{source.x1}, {source.x2} 2x" media="(min-width: {source.width}px)" />
    {/each}

    <img {src} {alt} {width} loading="lazy" class={style} />
  </picture>
  <Caption {caption} />
{/if}

<style>
img {
  min-height: 20rem;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin: var(--margin-m) 0 0 0;  
}
@media (min-width: 769px) {
  img {
    display: block;
    margin: var(--margin-m) auto 0 auto;
    height: 100%;
  }
}
</style>
