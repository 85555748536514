<script>
    export let text
    export let onIntersect = { fromBottom: false, fromLeft: false }
    export let isVisible
    export let caption
    </script>
    
    <div class="quote-container">

        <div class="text-container">
            <p
                class:visible={isVisible}
                class:from-bottom={onIntersect.fromBottom}
                class:from-left={onIntersect.fromLeft}
                class:none={!onIntersect.fromBottom && !onIntersect.fromLeft}>
                 {@html text}
            </p>

            {#if caption}
                <p class="caption">{@html caption}</p>
            {/if}
        </div>
        
    </div>
    
    <style>
    .quote-container{
        padding: 16px 0px;
        max-width: 30em;
        margin-left: 16px;
    }

    .text-container{
        border-left: solid;
        border-image-slice: 1;
        border-width: 0 0 0 4px;
        border-image-source: linear-gradient(180deg, #77D1EE, #003478);
    }

    @media (min-width: 480px){
        .quote-container{
            padding: 16px 0px 16px 28px;
            margin: auto;
        }
    }

    p {
      padding: 0 1rem;
      margin: 0 auto 1.5rem;
      font-size: 1rem;
      line-height: 1.44;
      transition: 0.3s all ease-in-out;
      font-weight: 400;
      color: #003478;
      font-size: 1.375rem;
    }
    
    @media (min-width: 769px) {
      p {
        font-size: 1.5rem;
      }
    }
    
    /*
     * additional classes
     */
    
    .from-left,
    .from-bottom {
      opacity: 0;
    }
    
    .from-left {
      transform: translate(-20px, 0);
    }
    
    .from-bottom {
      transform: translate(0, 20px);
    }
    
    .none,
    .visible {
      transform: none;
      opacity: 1;
    }
    .caption{
        font-size: 0.875em;
        font-weight: 400;
        color: #8C8C8C;
    }
    </style>
    