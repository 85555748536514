module.exports.absoluteUrl = (path) => {
  if (typeof window !== 'undefined') {
    const hrefNoTrailingSlash = window.location.href
      .split(/[?#]/)[0] // remove querystring and hashes
      .replace(/\/$/, '') // remove trailing slash
    return hrefNoTrailingSlash + '/' + path
  }

  return ''
}
