<script>
import { onMount } from 'svelte'
import Img from './Img.svelte'
import Video from './Video.svelte'

export let title
export let subtitle
export let img
export let video
export let logo

// Use this technique and not `intro: true`. With prerender, it would
// result in a FOUC. Ref: https://stackoverflow.com/a/64444463/2599199
let fadeClass = ''
onMount(() => {
  fadeClass = 'fade'
})
</script>

<section>
  <div class="cover-bg">
    {#if logo}
    <a
      target="_blank"
      rel="noopener noreferrer"
      class="logo-rep {fadeClass}"
      title="Vai al sito di Mission Bambini"
      href="https://missionbambini.org/progetto/cuore-di-bimbi-tanti-piccoli-cuori-che-riprendono-a-battere-forte/">
      <img src={logo} alt="Logo Mission Bambini" />
    </a>
    {/if}
    <div class="text">
      <div class="text-inner">
        <h1 class={fadeClass}>{title}</h1>
        {#if subtitle}
          <h2 class={fadeClass}>{subtitle}</h2>
        {/if}
      </div>
      <Video {...{
        src: 'assets/shapesHeroDesktop.mp4',
        mobileSrc: 'assets/shapesHeroMobile.mp4', 
        absolute: true        
      }} />
    </div>
    <div class="arrow">
      <svg
        width="32"
        height="16"
        viewBox="0 0 34 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L17 17L33 1"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4px" />
      </svg>
    </div>
  </div>
  <div class="video-wrapper">
    {#if img}
      <Img {...img} />
    {:else if video}
      <Video {...video} />
    {/if}
  </div>
</section>

<style>
section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  background: transparent;
}

.cover-bg {
  height: 100vh;
  min-height: 35rem;
  width: 100%;  
  padding: 1rem;
  padding-top: 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blue-primary-500);
  z-index: -2;
}

.video-wrapper {
  width: 100%;
  height: 20rem;
}

.logo-rep {
  padding: 1rem;
  transform: translate(0) !important;
}

.logo-rep img {
  width: 5rem;
}

.text {
  width: 100%;
  text-align: center;
  padding: 0 1rem;
  color: #f2f2f2;
}

.text-inner {
  max-width: 30rem;
  margin: 0 auto;
  padding: 0 1rem;
}

h1,
h2,
.share {
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
}

h1 {
  font-size: 3em;
  margin-bottom: 0.5rem;
}

.fade {
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: all;
  opacity: 1;
}

h1.fade,
h2.fade,
.share.fade {
  transform: none;
}

.logo-rep.fade {
  transition-delay: 0.15s;
  transform: translate(-50%, 0);
}

h2.fade {
  transition-delay: 0.45s;
}

.share.fade {
  transition-delay: 0.6s;
}

.arrow {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: bounce;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
@media (min-width: 1024px) {
  section {
    height: 100vh;
  }
  .video-wrapper {
    width: 50%;
    height: 100%;
  }
  .cover-bg {
    height: 100%;
    width: 50%;
    padding: 6rem 10%;
    align-items: baseline;    
    background-color: var(--blue-primary-500);
    z-index: -2;
  }
  .logo-rep {
    padding: 0;
  }

  .logo-rep img {
    max-width: 5rem;
  }
  .text {
    text-align: left;
    padding: 0;
  }
  .text-inner {
    padding: 0;
    margin: 0;
  }
  h1 {
    font-size: 500%;
    line-height: 100%;
    max-width: 60%;
  }
  h1.fade {
    font-size: 500%;
    line-height: 100%;
    max-width: 60%;
  }
  .arrow {
    opacity: 0;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 3em;
  }
}
</style>
