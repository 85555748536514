<script>
export let number
export let duration
export let cents = false
export let label
export let description
export let percentage = false
export let sign
export let isVisible
export let clicked
export let center = false
export let unstyle = false 
export let backgroundColor = 'transparent'
export let color = '#00B7FF'
export let descrColor = '#00B7FF'
export let caps = false
export let inline = false
let minWidth = number ? 3.5 * number.toString().length : 0
let animatedValue = 0
let isAnimating = false
const animateValue = (start, end, duration) => {
  let rAF
  let startTimestamp = null
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)
    animatedValue = Math.floor(progress * (end - start) + start)
    if (cents) {
      if (animatedValue < 10) {
        animatedValue = '0,0' + animatedValue
      } else if (animatedValue >= 10 && animatedValue < 100) {
        animatedValue = '0,' + animatedValue
      } else {
        const s = animatedValue.toString()
        animatedValue = s.slice(0, 1) + ',' + s.slice(1, 3)
      }
    }
    if (progress < 1) {
      rAF = window.requestAnimationFrame(step)
    } else {
      window.cancelAnimationFrame(rAF)
    }
  }
  rAF = window.requestAnimationFrame(step)
}
const runAnimation = (_) => {
  if (!isAnimating) {
    isAnimating = true
    animateValue(0, number, duration)
  }
}
$: {
  if (isVisible) runAnimation()
  if (clicked) {
    isAnimating = false
    runAnimation()
  }
}
</script>

<div class="wrapper" class:unstyle>
  <div style="background:{backgroundColor};" class="inner" class:inline class:center>
    {#if label}<div class="label">{label}</div>{/if}
    <div style="color:{color};min-width:{minWidth}rem;" class="value-wrapper">
      <div class="animated">
        {#if sign}{sign}{/if}{animatedValue.toLocaleString('it')}{#if percentage}%{/if}
      </div>
    </div>

    <div style="color: {descrColor}" class:caps class="description">{@html description}</div>
  </div>
</div>

<style>
.wrapper {  
  display: flex;
  flex-direction: column;
  margin: var(--margin-m);  
}

.wrapper.unstyle {
    width: auto;
    margin: 0;
    padding: 0;
}

.inner {
  box-sizing: border-box;
  padding: var(--margin-xs) 0 1rem 0;  
}

.inner.center {
  text-align: center;
}

.value-wrapper {
  margin: 0 auto;
}
.animated {
  font-weight: 700;
  font-size: var(--font-super);
  line-height: 5rem;
}
.label {
  font-size: var(--font-body-sm);
}
.description {
  font-size: var(--font-title-xs);
  line-height: 1.5rem;
  font-family: 'Saira Semi Condensed', sans-serif;
  letter-spacing: 0.06rem;
  /* padding-top: var(--margin-xs); */
  margin: 0 auto;
  font-weight: 500;
}
.caps {
  text-transform: uppercase;
  font-weight: 700;
}
@media (min-width: 768px) {
  .wrapper {    
    margin: var(--margin-m) auto;    
    width: 60%;
  }  
  .inline {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .inline div {
    margin: 0;
  }
}
</style>
