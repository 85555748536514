<script>
export let text
export let small = false
export let onIntersect = { fromBottom: false, fromLeft: false }
export let isVisible
export let minibold
export let bold
export let box
export let center = false
export let map = false
</script>

<p
  class:visible={isVisible}
  class:small
  class:from-bottom={onIntersect.fromBottom}
  class:from-left={onIntersect.fromLeft}
  class:minibold
  class:bold
  class:box
  class:map
  class:none={!onIntersect.fromBottom && !onIntersect.fromLeft}
  class:center>
  {@html text}
</p>

<style>
p {
  margin: 0 auto var(--margin-xs);
  font-size: var(--font-body);
  line-height:  1.35rem;
  transition: 0.3s all ease-in-out;
  font-weight: 400;  
}

/*
 * additional classes
 */
.small {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.from-left,
.from-bottom {
  opacity: 0;
}

.from-left {
  transform: translate(-20px, 0);
}

.from-bottom {
  transform: translate(0, 20px);
}

.none,
.visible {
  transform: none;
  opacity: 1;
}

.minibold {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-title-xs);
  text-transform: uppercase;
  font-family: 'Saira Semi Condensed', sans-serif;
  color: var(--blue-primary-700)
}

.bold {
  font-weight: 800;  
  font-size: var(--font-title-sm);
}

.box {
  background: var(--blue-primary-400);
  border: 4px solid var(--blue-primary-500);
  color: #FFF;
  padding: 1rem;
}
@media (min-width: 769px) {
  .box {
    margin: var(--margin) auto;
  }
}
.center {
  text-align: center;
}
</style>
