<script>
  import { absoluteUrl } from '../lib/with-url'
  
  export let src
  export let title
  export let caption
  export let proportions
  export let isVisible
  
  let clientWidth
  $: visible = isVisible
  
  </script>
  
  <div class="outer"  bind:clientWidth style="height: {(clientWidth * proportions) * 0.93}px;">
    <div class="wrapper" class:visible>
      <iframe class="iframe" {title} src={src} />
    </div>
  </div>
  
  {#if caption}
    <caption>{caption}</caption>
  {/if}
  
  <style>
  
  .wrapper {
    position: relative;
    padding-bottom: 56.25%;
    opacity: 0;
    transition: 0.3s opacity ease-in-out;
  }
  
  iframe {
    position: absolute;
    top: 0;
    left: 2.5%;
    width: 95%;
    height: 90%;
    border: 0;
    outline: none;
  }
  
  .outer {
    max-width: 640px;
    margin: auto;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 668px){
    .outer{
      max-width: 480px;
    }
  }
  
  @media (min-width: 1024px){
    .outer{
      max-width: 1000px;
    }
  }
  
  .visible {
    opacity: 1;
  }
  
  caption {
    display: block;
    text-align: center;
    font-weight: 400;
    font-family: 'Montserrat';
    margin-bottom: 1em;
    font-size: 14px;
    color: #4A4A49;
  }
  </style>
  