<script>
import { onMount, onDestroy } from 'svelte'
import Carousel from '@beyonk/svelte-carousel/src/Carousel.svelte'
import Img from './Img.svelte'
import Caption from './Caption.svelte'

export let isVisible
export let slides
export let contained
export let module
export let padded
export let perPage = 3
export let caption

const MODULE_TYPES = {
  img: Img,
}

let isFull = false

let slideIndexes = [0]
let perPageComp = perPage

const handleChange = (e) => {
  const index = e ? e.detail.currentSlide : slideIndexes[0]
  const indexes = Array(perPageComp)
    .fill(index)
    .map((v, i) => v + i)
  slideIndexes = indexes
}

const handlePerPage = (_) => {
  if (typeof window !== 'undefined') {
    const k = Object.keys(perPage)
      .reverse()
      .find((v) => window.innerWidth >= v)
    perPageComp = perPage[k]
    isFull = perPageComp === slides.length
  }
}

onMount(() => {
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', handlePerPage)
    handlePerPage()
    handleChange()
  }
})

onDestroy(() => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('resize', handlePerPage)
  }
})
</script>

<div class:no-drag={isFull} class:contained class="wrapper">
  <Carousel on:change={handleChange} controls={false} {perPage} easing="ease-in-out" duration={500}>
    {#each slides as slide, i}
      <div class="slide-content">
        <div class="inner">
          <div class:padded={padded || false}>
            <svelte:component
              this={MODULE_TYPES[module]}
              {...slide}
              isVisible={slideIndexes.includes(i) && isVisible} />
          </div>
        </div>
      </div>
    {/each}
  </Carousel>
  <Caption {caption} />
</div>

<style>
.slide-content,
.inner {
  height: 100%;
}

.inner {
  text-align: center;
}

.padded {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .wrapper.contained {
    margin: 0 auto;
  }
}

.no-drag {
  pointer-events: none;
}
</style>
