<script>
import { pie, arc } from 'd3-shape'
import Caption from './Caption.svelte'
import LegendItem from './LegendItem.svelte'
import { numberWithCommas } from '../lib/number-utils'
import { animateAngle } from '../lib/custom-transition'
export let title
export let data
export let caption
export let captionColor
export let isVisible
const DIAM = 280
const DONUT_THICKNESS = 120
const RADIUS = DIAM / 2
const OUTER_RADIUS = RADIUS - 20
const INNER_RADIUS = OUTER_RADIUS - DONUT_THICKNESS
const pieFn = pie().value((d) => d.value)
const entries = pieFn(data)
const arcFn = arc().innerRadius(INNER_RADIUS).outerRadius(OUTER_RADIUS)
</script>

<div class="wrapper">
  <p class="title">{title}</p>

  <div class="chart-wrapper">
    <div class="chart">
      <svg width={DIAM} height={DIAM}>
        <g transform="translate({RADIUS} {RADIUS})">
          {#if isVisible}
            {#each entries as d}
              <path
                in:animateAngle={{ arcFn, datum: d }}
                d={arcFn(d)}
                fill={d.data.color}
                stroke="#8F600D"
                stroke-width={'2px'} />
            {/each}
          {/if}
        </g>
      </svg>
    </div>
    <ul class="legend">
      {#each data as d}
        <li>
          <LegendItem color={d.color}>
            <p>{d.label}, <strong>{numberWithCommas(d.value)}%</strong></p>
          </LegendItem>
        </li>
      {/each}
    </ul>
  </div>

  <Caption {caption} color={captionColor} />
</div>

<style>
.wrapper {
  margin: 0 auto;
}
.title {
  font-size: var(--font-title-xs);
  line-height: 1.5rem;
  font-weight: 700;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
}
.chart {
  align-self: center;
}

.legend {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.legend li {
  padding: 0;
  margin-bottom: var(--margin-xs);
}
</style>
