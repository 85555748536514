<script>
export let color
export let labelColor = 'black'
</script>

<div class="legend-item">
  <div class="legend-color" style="background: {color};" />
  <div class="legend-label" style="color: {labelColor};">
    <slot />
  </div>
</div>

<style>
.legend-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
}
@media (max-width: 480px) {
  .legend-item {
    margin: 0.25rem 0;
  }
}
.legend-color {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  border: 2px solid #8f600d;
  border-radius: 50%;
}
.legend-label {
  font-size: 0.875rem;
}
</style>
