<script>
import { onMount } from 'svelte'
import { fade } from 'svelte/transition'
import { scaleLinear } from 'd3-scale'
import { animateHeight } from '../lib/custom-transition.js'
import { numberWithCommas } from '../lib/number-utils'
import { absoluteUrl } from '../lib/with-url.js'

export let isVisible
export let data

const width = 300
const height = 220
const margin = { top: 40, left: 40, right: 40, bottom: 40 }

const xStart = margin.left
const xEnd = width - margin.right
const y = scaleLinear()
  .domain([0, Math.max(...data.map((d) => d.value))])
  .range([height - margin.bottom, margin.top])

const firstDatum = data[0]
const lastDatum = data[1]

const fadeDelay = { delay: 600 }
const fadeDelayLong = { delay: 700 }
</script>

<svg {width} {height}>
  {#if isVisible}
    <text
      fill="#C5003E"
      transition:fade={fadeDelayLong}
      x={xStart}
      y={y(firstDatum.value) - 10}
      text-anchor="middle">{numberWithCommas(firstDatum.value)}</text>
    <text
      fill="#C5003E"
      transition:fade={fadeDelayLong}
      x={xEnd}
      y={y(lastDatum.value) - 10}
      text-anchor="middle">{numberWithCommas(lastDatum.value)}</text>

    <rect
      in:animateHeight
      x={xStart}
      y={y(firstDatum.value)}
      width={xEnd - xStart}
      height={y(0) - y(firstDatum.value)}
      fill="#C5003E" />

    <g class="x-axis" transform="translate(0 {height - margin.bottom})">
      <text transition:fade={fadeDelayLong} x={xStart} y={20} text-anchor="middle"
        >{data[0].label}</text>
      <text transition:fade={fadeDelayLong} x={xEnd} y={20} text-anchor="middle"
        >{data[1].label}</text>

      <line x1={xStart - 20} x2={xEnd + 20} y1="0" y2="0" stroke="black" />
    </g>
  {/if}
</svg>
