<script>
import { onMount } from 'svelte'

import Module from './Module.svelte'
import Symbol from './Symbol.svelte'
export let shape = 'square'
export let fill = '#00B7FF'
export let stroke = '#007AFF'
export let number = 15861
export let factor = 100
export let caption = 'bambini aiutati'
export let onIntersect = { fromBottom: false, fromLeft: false }

let length = number / factor
</script>

<div
  class:from-bottom={onIntersect.fromBottom}
  class:from-left={onIntersect.fromLeft}
  class:none={!onIntersect.fromBottom && !onIntersect.fromLeft}
  class="symwrapper"
  style="--fill:{fill}; --stroke: {stroke}">
  <Module moduleType={Symbol} module={{ shape: shape }} />
  <div class="arrow">
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.35355 4.87357C8.54882 4.67831 8.54882 4.36173 8.35355 4.16647L5.17157 0.984486C4.97631 0.789223 4.65973 0.789223 4.46447 0.984486C4.2692 1.17975 4.2692 1.49633 4.46447 1.69159L7.29289 4.52002L4.46447 7.34845C4.2692 7.54371 4.2692 7.86029 4.46447 8.05555C4.65973 8.25082 4.97631 8.25082 5.17157 8.05555L8.35355 4.87357ZM0 5.02002H8V4.02002H0V5.02002Z"
        fill="black" />
    </svg>
  </div>
  <p>{factor} {caption}</p>

  {#each { length } as _, i}
    <Module moduleType={Symbol} module={{ shape: shape }} />
  {/each}
</div>

<style>
.symwrapper {  
  display: flex;
  flex-wrap: wrap;
  margin: var(--margin-l) auto;
  align-items: center;  
}
:global(.symwrapper div) {
  width: 10% !important;
}

.arrow {
  padding: 0 0.5rem;
}
.arrow svg {
  width: 100%;
  height: 100%;
}
p {
  min-width: 80%;
  margin: 1.125rem 0;
}

@media (min-width: 992px) {
  .symwrapper {
    width: 50%;
  }
}

</style>
