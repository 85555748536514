<script>
import star from './Star.svelte'
import square from './Square.svelte'
import circle from './Circle.svelte'
import triangle from './Triangle.svelte'
export let shape = 'star'
export let isVisible
export let onIntersect = { fromBottom: true, fromLeft: true }
const SHAPES = {
  star: star,
  square: square,
  circle: circle,
  triangle: triangle,
}
</script>

<div
  class="symbol"
  class:from-bottom={onIntersect.fromBottom}
  class:from-left={onIntersect.fromLeft}
  class:none={!onIntersect.fromBottom && !onIntersect.fromLeft}>
  <svelte:component this={SHAPES[shape]} {isVisible} />
</div>

<style>
.symbol {
  width: 100% !important;
}
:global(.symbol svg) {
  width: 100%;
  height: 100%;
}
:global(.symbol path) {
  fill: var(--fill);
  stroke: var(--stroke);
  stroke-width: 2px;
}
</style>
