<script>
  export let size
  export let backgroundColor
</script>
<div style={`height: ${size}px; background-color: ${backgroundColor}`} />
<style>
  div {
    margin: var(--margin-m) 0;
  }
</style>
  