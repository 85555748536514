<script>
  export let selected
  export let text = "I PROGETTI"
  export let values
  export let disabled  
</script>
  
<div class="dropdown">
  <span>{text}</span>
  {#if !disabled}  
  <div class="selectDIV">
    <select class="selectByRegion" name="region" bind:value={selected}>
      <optgroup>
        {#each values as s}
          <option value={s.value}>{s.option.toUpperCase()}</option>
        {/each}
      </optgroup> 
    </select>
    <div class="arrow">
      <img src={'./assets/chevron.svg'} alt="arrow select" />
    </div>   
  </div>
  {:else}
    <span>{values[0].option.toUpperCase()}</span>
  {/if}
</div>
  
<style>
.dropdown {
  font-size: var(--font-title-xs);
  font-weight: 800;
  margin: var(--margin-xs) 0;
  color: var(--blue-primary-700);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;  
  border: 0;
  font-weight: 800;
  font-size: 0.95em;
  color: var(--blue-primary-700);
  border-bottom: 1px solid;
}
.selectDIV {  
  display: inline;
  position: relative;
}
.selectDIV .arrow {  
  position: absolute;
  top: 2px;
  right: 0;
}
</style>
 