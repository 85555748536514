<script>
    import { onMount, onDestroy } from 'svelte'
    import Caption from './Caption.svelte'
    import IntersectionObserver from "./IntersectionObserver.svelte";
    export let src
    export let mobileSrc
    export let cover = false
    export let caption
    export let captionColor
    export let threshold
    export let isVisible = true
    let time = 0
    let videoEl
  
    
    // Inline media queries for HTML5 videos don't work in Chrome :(
    // See: https://stackoverflow.com/questions/25907930/chrome-not-respecting-video-source-inline-media-queries
    let viewport = 'mobile'
    function setViewportMq(_) {
      viewport = window.innerWidth <= 480 ? 'mobile' : 'desktop'
    }
    onMount(() => {
      if (typeof window !== 'undefined' && mobileSrc) {
        window.addEventListener('resize', setViewportMq)
        setViewportMq()
      }
    })
    onDestroy(() => {
      if (typeof window !== 'undefined' && mobileSrc) {
        window.removeEventListener('resize', setViewportMq)
      }
    })
    const startVideo = (_) =>{
      time = 0;
    }
  
    </script>
  
    <IntersectionObserver on:intersecting={startVideo} {threshold}>
      <div class="wrapper" class:cover>
        {#if mobileSrc && viewport === 'mobile'}
          <video autoplay muted loop playsinline controls={false} bind:this={videoEl} bind:currentTime={time} class:visible={isVisible}>
            <source src={mobileSrc} type="video/mp4" />
          </video>
        {:else}
          <video autoplay muted loop playsinline controls={false} bind:this={videoEl} bind:currentTime={time} class:visible={isVisible}>
            <source {src} type="video/mp4" />
          </video>
        {/if}
        {#if caption}
          <Caption {caption} color={captionColor} />
        {/if}
      </div>
    </IntersectionObserver>
  
    <style>
    .wrapper {
      width: 100%;
      height: 100%;
    }
    video {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.3s opacity ease-in-out;
    }
  
    .visible{
      opacity: 1;
    }
  
    .cover video {
      object-fit: cover;
    }
    .wrapper:not(.cover) {
      max-width: 18rem;
      padding: 0 1rem;
      margin: 16px auto 16px;
    }
  
    </style>
    