<script>
export let color = '#FFD302'
export let backgroundColor = '#0000BA'
export let text
export let href = 'https://missionbambini.org/progetto/cuore-di-bimbi-tanti-piccoli-cuori-che-riprendono-a-battere-forte/'
export let header = false
</script>

<div class:padded={!header}>
  <a class:header class="btn" target="_blank" style="color: {color}; background-color: {backgroundColor}" href= {href}>
    {@html text}
  </a>
</div>

<style>
div {
  cursor: pointer;
} 
.btn {
  cursor: pointer;
  padding: 1rem;
  display: flex;
  margin: var(--margin-m) auto;
  justify-content: center;
  font-size: var(--font-body);
  line-height: 1.25rem;
  text-align: center;
  align-items: center;
  width: 100%;  
}
.header {
  width: 8.3125rem;
  font-size: 14px;
  height: 1.9375rem;
  margin: 0;
  padding: 0.5rem 0.75rem;
  text-align: center; 
}

@media (min-width: 992px) {
  .btn:not(.header) {
    width: 70%;
  }  
}

</style>
