
module.exports.data = {
  hero: {
    title: 'Annual Report 2021',
    video: {
      src: 'assets/videoHeroDesktop.mp4',
      mobileSrc: 'assets/videoHeroMobile.mp4',
      cover: true,
    },
  },
  sections: [
    {
      backgroundColor: '#FFDB48',
      intersectionObserver: { threshold: 0.5, once: true },
      fullWidth: true,
      modules: [
        {
          type: 'h2',
          text: 'I risultati del 2021',
          map: true,
        },
        { type: 'map' },
        { type: 'projects-grid' },
      ],
    },
    {
      modules: [
        {
          type: 'h2',
          text: 'Le risorse del',
          highlight: '2021',
        },
        {
          type: 'animated-number',
          number: 50003,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Donazioni',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'p',
          text: 'grazie a',
          center: true,
        },
        {
          type: 'animated-number',
          number: 19753,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Donatori',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'divider',
          size: 2,
          backgroundColor: '#FFDB48',
        },
        {
          type: 'animated-number',
          number: 490,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Volontari',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'p',
          text: 'per un totale di',
          center: true,
        },
        {
          type: 'animated-number',
          number: 3593,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Ore donate',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'barChart',
          color: '#FFDB48',
          text: 'Volontari per tipologia di attività',
          data: [
            {
              label: 'Sensibilizzazione e raccolta fondi',
              value: 230,
            },
            { label: 'Formazione', value: 178 },
            { label: 'Volontariato di sede', value: 55 },
            { label: 'Campi estivi', value: 20 },
            { label: 'Tutoring agli studenti', value: 7 },
          ],
        },
        {
          type: 'divider',
          size: 2,
          backgroundColor: '#FFDB48',
        },
        {
          type: 'animated-number',
          number: 114,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Aziende partner',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'donut',
          title: 'Fondi raccolti da aziende per tipologia di attività (%)',
          data: [
            { label: 'CSR Corporate Social Responsibility', color: '#DD9E2F', value: 53 },
            { label: 'Donazioni in kind', color: '#FFC129', value: 31 },
            { label: 'CRM Cause Related Marketing', color: '#FFD302', value: 12 },
            { label: 'Employee engagement', color: '#FFF4DB', value: 4 },
          ],
        },
        {
          type: 'divider',
          size: 2,
          backgroundColor: '#FFDB48',
        },
        {
          type: 'animated-number',
          number: 18,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Fondazioni ed enti partner',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'donut',
          title: 'Fondi raccolti da fondazioni per tipologia di ente (%)',
          data: [
            { label: 'Fondazioni bancarie', color: '#DD9E2F', value: 50 },
            {
              label: 'Altre fondazioni private (di famiglia o altro)',
              color: '#FFC129',
              value: 23,
            },
            { label: 'Fondazioni di impresa', color: '#FFD302', value: 22 },
            { label: 'Enti pubblici', color: '#FFF4DB', value: 5 },
          ],
        },
        {
          type: 'divider',
          size: 2,
          backgroundColor: '#FFDB48',
        },
        {
          type: 'animated-number',
          number: 32,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Collaboratori',
          color: '#DD9E2F',
          descrColor: '#8F600D',
        },
        {
          type: 'barChart',
          color: '#FFDB48',
          text: "Collaboratori per fascia d'età",

          data: [
            {
              label: '20-29 anni',
              value: 6,
            },
            { label: '30-39 anni', value: 11 },
            { label: '39-50 anni', value: 13 },
            { label: '50 anni e più', value: 2 },
          ],
        },
      ],
    },
    {
      modules: [
        {
          type: 'h2',
          text: 'I nostri risultati dal',
          highlight: '2000',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          bold: true,
          text: 'progetti educazione',
        },
        {
          type: 'p',
          text: 'Obiettivo: contrastare la povertà educativa minorile garantendo l’accesso a percorsi di istruzione, educazione e formazione di qualità, anche a supporto dell’inclusione lavorativa e sociale, in Italia e all’estero.',
          onIntersect: { fromBottom: true },
        },
        {
          type: 'p',
          box: true,
          text: 'La povertà educativa è la condizione in cui un bambino o un adolescente si trova privato del diritto all’apprendimento: dalle opportunità culturali ed educative al diritto al gioco. Povertà economica e povertà educativa si alimentano a vicenda.',
          onIntersect: { fromBottom: true },
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: 'progetti educazione in italia / 0-6 anni',
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              style: 'cover',
              alt: 'Immagine di un paesaggio',
              src: 'assets/Carosello01_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello01_01-1440-1x.jpg',
                  x2: 'assets/Carosello01_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello01_01-1024-1x.jpg',
                  x2: 'assets/Carosello01_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello01_01-768-1x.jpg',
                  x2: 'assets/Carosello01_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello01_01-320-1x.jpg',
                  x2: 'assets/Carosello01_01-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'Immagine di un paesaggio',
              src: 'assets/Carosello01_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello01_02-1440-1x.jpg',
                  x2: 'assets/Carosello01_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello01_02-1024-1x.jpg',
                  x2: 'assets/Carosello01_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello01_02-768-1x.jpg',
                  x2: 'assets/Carosello01_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello01_02-320-1x.jpg',
                  x2: 'assets/Carosello01_02-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'Immagine di un paesaggio',
              src: 'assets/Carosello01_03.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello01_03-1440-1x.jpg',
                  x2: 'assets/Carosello01_03-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello01_03-1024-1x.jpg',
                  x2: 'assets/Carosello01_03-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello01_03-768-1x.jpg',
                  x2: 'assets/Carosello01_03-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello01_03-320-1x.jpg',
                  x2: 'assets/Carosello01_03-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'Immagine di un paesaggio',
              src: 'assets/Carosello01_04.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello01_04-1440-1x.jpg',
                  x2: 'assets/Carosello01_04-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello01_04-1024-1x.jpg',
                  x2: 'assets/Carosello01_04-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello01_04-768-1x.jpg',
                  x2: 'assets/Carosello01_04-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello01_04-320-1x.jpg',
                  x2: 'assets/Carosello01_04-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'star',
          number: 15861,
        },
        {
          type: 'animated-number',
          number: 15861,
          cents: false,
          duration: 1000,
          center: true,
          description:
            'Bambini beneficiari di servizi educativi per la prima infanzia e sostegno alle famiglie',
          label: "Fascia d'età 0-6 anni",
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/progetto/scintilla-prima-infanzia-mission-bambini/',
        },
      ],
    },

    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: 'progetti educazione in italia / 6-16 anni',
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              type: 'img',
              alt: 'bambini palestra',
              src: 'assets/slide-3-pos-1.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/slide-3-pos-1-1440-1x.jpg',
                  x2: 'assets/slide-3-pos-1-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/slide-3-pos-1-1024-1x.jpg',
                  x2: 'assets/slide-3-pos-1-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/slide-3-pos-1-768-1x.jpg',
                  x2: 'assets/slide-3-pos-1-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/slide-3-pos-1-320-1x.jpg',
                  x2: 'assets/slide-3-pos-1-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'bambini laboratorio',
              src: 'assets/slide-3-pos-2.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/slide-3-pos-2-1440-1x.jpg',
                  x2: 'assets/slide-3-pos-2-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/slide-3-pos-2-1024-1x.jpg',
                  x2: 'assets/slide-3-pos-2-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/slide-3-pos-2-768-1x.jpg',
                  x2: 'assets/slide-3-pos-2-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/slide-3-pos-2-320-1x.jpg',
                  x2: 'assets/slide-3-pos-2-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'bambino studio',
              src: 'assets/Carosello02_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello02_01-1440-1x.jpg',
                  x2: 'assets/Carosello02_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello02_01-1024-1x.jpg',
                  x2: 'assets/Carosello02_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello02_01-768-1x.jpg',
                  x2: 'assets/Carosello02_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello02_01-320-1x.jpg',
                  x2: 'assets/Carosello02_01-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'bambini studio',
              src: 'assets/Carosello02_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello02_02-1440-1x.jpg',
                  x2: 'assets/Carosello02_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello02_02-1024-1x.jpg',
                  x2: 'assets/Carosello02_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello02_02-768-1x.jpg',
                  x2: 'assets/Carosello02_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello02_02-320-1x.jpg',
                  x2: 'assets/Carosello02_02-320-2x.jpg',
                },
              ],
            },
          ],
        },

        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'square',
          number: 17849,
          caption: 'bambini e ragazzi aiutati',
        },
        {
          type: 'animated-number',
          number: 17849,
          cents: false,
          duration: 1000,
          center: true,
          description:
            'Bambini, ragazze e ragazzi aiutati tramite sostegno allo studio e progetti innovativi nelle scuole',
          label: "Fascia d'età 6-16 anni",
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/progetto/stringhe-un-passo-avanti-per-contrastare-la-poverta-educativa-e-la-segregazione-scolastica/',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: 'progetti educazione in italia / 16-24 anni',
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              type: 'img',
              alt: 'ragazzo formazione',
              src: 'assets/Carosello03.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello03-1440-1x.jpg',
                  x2: 'assets/Carosello03-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello03-1024-1x.jpg',
                  x2: 'assets/Carosello03-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello03-768-1x.jpg',
                  x2: 'assets/Carosello03-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello03-320-1x.jpg',
                  x2: 'assets/Carosello03-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'ragazzi formazione',
              src: 'assets/slide-4-pos-2.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/slide-4-pos-2-1440-1x.jpg',
                  x2: 'assets/slide-4-pos-2-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/slide-4-pos-2-1024-1x.jpg',
                  x2: 'assets/slide-4-pos-2-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/slide-4-pos-2-768-1x.jpg',
                  x2: 'assets/slide-4-pos-2-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/slide-4-pos-2-320-1x.jpg',
                  x2: 'assets/slide-4-pos-2-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'square',
          number: 1361,
          caption: 'giovani aiutati',
        },
        {
          type: 'animated-number',
          number: 1361,
          cents: false,
          duration: 1000,
          center: true,
          description:
            'Giovani aiutati attraverso percorsi di formazione o di inserimento lavorativo',
          label: "Fascia d'età 16-14 anni",
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/progetto/allenamenti-per-il-futuro-unopportunita-per-i-giovani-neet/',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: "progetti educazione all'estero",
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              style: 'cover',
              alt: 'bambini primo piano',
              src: 'assets/Carosello04_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello04_01-1440-1x.jpg',
                  x2: 'assets/Carosello04_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello04_01-1024-1x.jpg',
                  x2: 'assets/Carosello04_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello04_01-768-1x.jpg',
                  x2: 'assets/Carosello04_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello04_01-320-1x.jpg',
                  x2: 'assets/Carosello04_01-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'bambini scuola',
              src: 'assets/slide-5-pos-2.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/slide-5-pos-2-1440-1x.jpg',
                  x2: 'assets/slide-5-pos-2-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/slide-5-pos-2-1024-1x.jpg',
                  x2: 'assets/slide-5-pos-2-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/slide-5-pos-2-768-1x.jpg',
                  x2: 'assets/slide-5-pos-2-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/slide-5-pos-2-320-1x.jpg',
                  x2: 'assets/slide-5-pos-2-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'ragazzi scuola',
              src: 'assets/slide-5-pos-3.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/slide-5-pos-3-1440-1x.jpg',
                  x2: 'assets/slide-5-pos-3-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/slide-5-pos-3-1024-1x.jpg',
                  x2: 'assets/slide-5-pos-3-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/slide-5-pos-3-768-1x.jpg',
                  x2: 'assets/slide-5-pos-3-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/slide-5-pos-3-320-1x.jpg',
                  x2: 'assets/slide-5-pos-3-320-2x.jpg',
                },
              ],
            },
            {
              style: 'cover',
              alt: 'bambini libri',
              src: 'assets/slide-5-pos-4.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/slide-5-pos-4-1440-1x.jpg',
                  x2: 'assets/slide-5-pos-4-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/slide-5-pos-4-1024-1x.jpg',
                  x2: 'assets/slide-5-pos-4-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/slide-5-pos-4-768-1x.jpg',
                  x2: 'assets/slide-5-pos-4-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/slide-5-pos-4-320-1x.jpg',
                  x2: 'assets/slide-5-pos-4-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'square',
          number: 28151,
          caption: 'bambini e ragazzi aiutati',
        },
        {
          type: 'animated-number',
          number: 28151,
          cents: false,
          duration: 1000,
          center: true,
          width: 50,
          description:
            'Bambini, ragazze e ragazzi aiutati tramite progetti di educazione e accoglienza',
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/cosa-facciamo/educazione/educazione-estero',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          text: "progetto borse rosa all'estero",
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              type: 'img',
              alt: 'bambine primo piano',
              src: 'assets/Carosello05.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello05-1440-1x.jpg',
                  x2: 'assets/Carosello05-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello05-1024-1x.jpg',
                  x2: 'assets/Carosello05-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello05-768-1x.jpg',
                  x2: 'assets/Carosello05-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello05-320-1x.jpg',
                  x2: 'assets/Carosello05-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'ragazza diploma',
              src: 'assets/slide-6-pos-2.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/slide-6-pos-2-1440-1x.jpg',
                  x2: 'assets/slide-6-pos-2-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/slide-6-pos-2-1024-1x.jpg',
                  x2: 'assets/slide-6-pos-2-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/slide-6-pos-2-768-1x.jpg',
                  x2: 'assets/slide-6-pos-2-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/slide-6-pos-2-320-1x.jpg',
                  x2: 'assets/slide-6-pos-2-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'circle',
          number: 1822,
          caption: 'ragazze aiutate',
        },
        {
          type: 'animated-number',
          number: 1822,
          cents: false,
          duration: 1000,
          center: true,
          description: 'Ragazze aiutate con percorsi di istruzione secondaria e terziaria',
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
          href: '//missionbambini.org/progetto/empowerment-femminile-quando-listruzione-e-una-scelta-che-dura-una-vita/',
        },
      ],
    },
    {
      modules: [
        {
          type: 'p',
          minibold: true,
          bold: true,
          text: 'progetti salute',
        },
        {
          type: 'p',
          text: 'Obiettivo: promuovere la prevenzione e garantire l’accesso a cure tempestive per tutelare la salute di bambini e ragazzi, con particolare riferimento a minori affetti da cardiopatie infantili nei Paesi con un sistema sanitario carente.',
          onIntersect: { fromBottom: true },
        },
        {
          type: 'gallery',
          module: 'img',
          perPage: 1,
          contained: true,
          slides: [
            {
              type: 'img',
              alt: 'bambino sorriso ospedale',
              src: 'assets/Carosello06_01.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello06_01-1440-1x.jpg',
                  x2: 'assets/Carosello06_01-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello06_01-1024-1x.jpg',
                  x2: 'assets/Carosello06_01-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello06_01-768-1x.jpg',
                  x2: 'assets/Carosello06_01-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello06_01-320-1x.jpg',
                  x2: 'assets/Carosello06_01-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'medici sala operatoria',
              src: 'assets/slide-7-pos-2.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/slide-7-pos-2-1440-1x.jpg',
                  x2: 'assets/slide-7-pos-2-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/slide-7-pos-2-1024-1x.jpg',
                  x2: 'assets/slide-7-pos-2-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/slide-7-pos-2-768-1x.jpg',
                  x2: 'assets/slide-7-pos-2-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/slide-7-pos-2-320-1x.jpg',
                  x2: 'assets/slide-7-pos-2-320-2x.jpg',
                },
              ],
            },
            {
              type: 'img',
              alt: 'bambina thumbs up',
              src: 'assets/Carosello06_02.jpg',
              sources: [
                {
                  width: 1440,
                  x1: 'assets/Carosello06_02-1440-1x.jpg',
                  x2: 'assets/Carosello06_02-1440-2x.jpg',
                },
                {
                  width: 1024,
                  x1: 'assets/Carosello06_02-1024-1x.jpg',
                  x2: 'assets/Carosello06_02-1024-2x.jpg',
                },
                {
                  width: 768,
                  x1: 'assets/Carosello06_02-768-1x.jpg',
                  x2: 'assets/Carosello06_02-768-2x.jpg',
                },
                {
                  width: 320,
                  x1: 'assets/Carosello06_02-320-1x.jpg',
                  x2: 'assets/Carosello06_02-320-2x.jpg',
                },
              ],
            },
          ],
        },
        {
          type: 'symbols',
          onIntersect: {
            fromBottom: true,
          },
          shape: 'star',
          number: 2403,
          fill: '#007AFF',
          stroke: '#0000FF',
        },
        {
          type: 'animated-number',
          number: 2403,
          cents: false,
          duration: 1000,
          center: true,
          description:
            'Bambini operati al cuore, oltre a 22.178 bambini visitati e 505 medici locali formati grazie al Programma “Cuore di bimbi”',
          descrColor: '#007AFF',
        },
        {
          type: 'btn',
          text: 'Scopri di più sul progetto<svg width="25" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 16.8867L16 4.88672M16 4.88672H8M16 4.88672V12.8867" stroke="#FFD302" stroke-width="2"/></svg>',
        },
      ],
    },
    {
      backgroundColor: '#FFDB48',
      intersectionObserver: { threshold: 0.5, once: true },
      fullWidth: true,
      modules: [
        {
          type: 'p',
          text: 'La Fondazione Mission Bambini è nata in Italia nel 2000 per iniziativa dell’Ingegner Goffredo Modena e della sua famiglia. A inizio 2015 la Fondazione italiana ha dato vita a Mission Bambini Switzerland e a Friends of Mission Bambini fund negli USA. In 22 anni di attività il Gruppo Mission Bambini ha sostenuto oltre 1.400.000 bambini attraverso più di 1.900 progetti di aiuto in 75 Paesi del mondo.',
          center: true,
        },
        {
          type: 'btn',
          text: 'Scarica il Report',
          href: '//missionbambini.org/wp-content/uploads/Annual_Report_2021_LR.pdf',
        },
      ],
    },
  ],
  preFooter: {},
}
