import 'intersection-observer'
import './lib/modernizr-custom-3.6.0.js'
import App from './App.svelte'

const app = new App({
  target: document.getElementById('container'),
  hydrate: true,
})

export default app
