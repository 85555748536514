<script>
import { conf } from "../lib/const";


export let type = 'INT'
const pNamesINT = [
  'Educazione',
  'Educazione e accoglienza',
  'Borse Rosa',
  'Cuore di bimbi',
  'Altri progetti Salute',
]
const pNamesIT = ['Educazione 0-6', 'Educazione 6-16', 'Educazione 16-24', 'Salute']
const pNames = type === 'IT' ? pNamesIT : pNamesINT
const aNames = ['Educazione', 'Salute']

</script>

<div class="box">
  <strong>Come si legge</strong>
  <div class="section">
    <p>PROGETTI</p>
    {#each pNames as pName, i}
      <p>{i + 1}.{' ' + pName}</p>
    {/each}
  </div>  
  <div class="section">
    <p>AMBITO DI INTERVENTO</p>
    {#each aNames as aName, i}
    <div class="legend-field">      
      <div style={`background: ${conf.colors[i + 1]}`} class={`legend-color ${type === 'IT' ? 'circle' : ''}`} />
      {aName}
    </div>
    {/each}
  </div>
</div>

<style>
.box {  
  width: 100%;
  padding: 1rem;
  background: #fff4db;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: var(--margin-s);
}
.section {
  padding-top: 1rem;
}
.legend-color {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;  
}
.legend-color.circle {
  border-radius: 50%;
}

.legend-field {
  margin-top: 0.25rem;
  display: flex;
}
</style>
